// Lista
.faq-lista {}

// Item
.faq {
  float: left;
  width: 100%; 
  padding: 20px;
  margin-bottom: 10px;
  background: rgba(#000, .05);
  border-radius: 8px;
  transition: .3s;

  &:hover {
    background: rgba(#000, .1);
  }
  
  // Ícone
  .faq-pergunta i {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border: none;
    background: $cor_primaria;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    transition: .3s;
    
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 5px;
      width: 8px;
      height: 8px;
      border-top: 2px solid #FFF;
      border-right: 2px solid #FFF;
      transform: rotate(45deg);
    }
  }
  &.open .faq-pergunta i {
    transform: rotate(90deg);
  }
  
  // Pergunta
  .faq-pergunta {
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: $cor_fonte_escura;
    cursor: pointer;
    transition: .3s;

    // MOBILE
    @include mq(s) {
      font-size: 16px;
    }
    
    &:hover {
      color: $cor_primaria;
    }
  }
  
  // Resposta
  .faq-resposta {
    float: left;
    width: 100%;
    margin-top: 10px;
    display: none;

    .texto {
      font-weight: 300;
      color: $cor_fonte_escura;

      p:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
