// Seção Posts
.blog-posts {
}

// Lista de posts
.lista-posts {
  float: left;
  width: 100%;
}

// Bloco post
.bloco-post {
  position: relative;
  margin-bottom: 30px;

  // MOBILE
  @include mq(s) {
    margin-bottom: 40px;          
  }

  // Foto
  .bloco-post-foto {
    overflow: hidden;

    // MOBILE
    @include mq(s) {
      margin-bottom: 20px;
    }

    img {
      transition: .5s ease-out;
    }
    &:hover img {
      transform: scale(1.1);
    }
  }

  // Título
  a:not(.btn) {
    display: inline-block;
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    color: $cor_fonte_escura;
    margin-bottom: 10px;
    transition: .3s ease-out;

    // TABLET
    @include mq(m) {
      font-size: 16px;
    }

    &:hover {
      color: $cor_primaria;
    }
  }

  // Autor e Data
  .bloco-post-data {
    margin-bottom: 10px;

    // TABLET
    @include mq(m) {
      font-size: 12px;           
    }

    // Autor
    b {
      color: $cor_fonte_escura
    }
  }

  // Descrição
  .bloco-post-texto {
    font-weight: 300;
    color: $cor_fonte_escura;

    // TABLET
    @include mq(m) {
      display: none;            
    }
  }

  // Botão
  .btn {}
}
