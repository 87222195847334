/* Arrows */
.slick-prev,
.slick-next {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  width: 50px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  color: transparent;
  transition: 0.2s ease-out;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 20px;
    height: 20px;
    border-top: 2px solid rgba(#fff, 0.8);
    border-right: 2px solid rgba(#fff, 0.8);
    transition: 0.2s ease-out;
  }

  &:hover::before,
  &:focus::before {
    border-color: $cor_primaria;
  }

  &.slick-disabled {
    cursor: default !important;
    opacity: 0 !important;
  }
}
.slick-slider:hover .slick-prev::before {
  left: 0;
}
.slick-slider:hover .slick-next::before {
  right: 0;
}

.slick-prev {
  left: 0;
  background: linear-gradient(to right, rgba(#000, 0.2), transparent);

  &::before {
    transform: rotate(225deg);
  }
}

.slick-next {
  right: 0;
  background: linear-gradient(to left, rgba(#000, 0.2), transparent);

  &::before {
    transform: rotate(45deg);
  }
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 0px;
}
.slick-dots {
  position: absolute;
  bottom: 0;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
    text-align: center;
    button {
      border: 0;
      background: rgba(#fff, 8);
      display: inline-block;
      height: 8px;
      width: 8px;
      padding: 0;
      outline: none;
      color: transparent;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s ease-out;
      &:hover,
      &:focus {
        background: rgba(#fff, 1);
      }
    }
    &.slick-active button {
      background: $cor_primaria;
      transform: scale(1.3);
    }
  }
}

.carrosel {
  float: left;
  width: 100%;
  width: calc(100% - 60px);
  margin-left: 30px;

  .slick-prev,
  .slick-next {
    width: 30px;
    background: none !important;

    &::before {
      border-color: rgba(#000, 0.3);
    }
    &:hover::before,
    &:focus::before {
      border-color: $cor_primaria;
    }
  }
  .slick-prev {
    left: -20px;
  }
  .slick-next {
    right: -20px;
  }
}
