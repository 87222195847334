.segura-equipe {
  text-align: center;
  width: 100%;
}

.teste55 {
  display: inline-block;
  float: none;
}

.bloco-equipe {
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 330px;
  margin-bottom: 20px;
  cursor: pointer;

  // TABLET
  @include mq(m) {
    height: 250px;
  }

  // MOBILE
  @include mq(s) {
    margin-bottom: 20px;
    height: auto;
  }

  img {
    transition: 0.3s ease-out;
  }
}

.bloco-equipe:hover img {
  transform: scale(1.1);
}

.bloco-equipe .conteudo-equipe {
  width: 100%;
  background: #222222;
  color: #fff;
  padding: 5px 0 10px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.bloco-equipe:hover .conteudo-equipe {
  padding-bottom: 40px;
}

.bloco-equipe .conteudo-equipe:before,
.bloco-equipe .conteudo-equipe:after {
  content: "";
  width: 60%;
  height: 38px;
  background: #222222;
  position: absolute;
  top: -18px;
  transform: rotate(15deg);
  z-index: -1;

  // MOBILE
  @include mq(s) {
    top: -15px;
  }
}

.bloco-equipe .conteudo-equipe:before {
  left: -3%;
}

.bloco-equipe .conteudo-equipe:after {
  right: -3%;
  transform: rotate(-15deg);
}

.bloco-equipe .nome {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 7px 0;
  position: relative;
}

.bloco-equipe .nome:before,
.bloco-equipe .nome:after {
  content: "";
  width: 13px;
  height: 97px;
  background: $cor_primaria;
  position: absolute;
  top: -67px;
  z-index: -2;
  transform: rotate(-74deg);

  // MOBILE
  @include mq(s) {
    top: -55px;
    width: 26px;
  }
}

.bloco-equipe .nome:before {
  left: 32%;
}

.bloco-equipe .nome:after {
  right: 32%;
  transform: rotate(74deg);
}

.bloco-equipe .area {
  display: block;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.bloco-equipe .link-equipe {
  padding: 0 0 15px 0;
  margin: 0;
  position: absolute;
  bottom: -40px;
  right: 0;
  left: 0;
  transition: all 0.5s ease 0s;
  a {
    color: $cor_primaria;
    transition: 0.3s ease-out;

    &:hover {
      color: $cor_secundaria;
    }
  }
}

.bloco-equipe:hover .link-equipe {
  bottom: 0;
}
